<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="container">
		<b-row class="mt-4">
			<b-col cols="12">
				<h2>
					{{ translate('update_profile_information') }}
				</h2>
			</b-col>
			<b-col
				class="pt-2"
				cols="12">
				<div v-html="translate('update_profile_information_description')" />
			</b-col>
		</b-row>
		<b-row>
			<b-col
				class="px-4 mt-2"
				cols="12">
				<onboarding-dynamic-field
					v-for="(info, field) in fields"
					:key="field"
					:field-code="field"
					:step-code="stepCode"
					:label="translate(field)"
					:initial-value="info.value"
					:dropdown-options="info.options"
					:force-change="!!info.force_update"
					:import-errors="importErrors"
					@onUpdate="updateValue" />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Onboarding from '@/util/Onboarding';
import { Onboarding as OnboardingMessage, Profile } from '@/translations';
import OnboardingDynamicField from '@/views/Onboarding/components/OnboardingDynamicField';
import { FIELDS_SORT_ORDER } from '@/settings/Onboarding';

export default {
	name: 'UpdateProfileInfoStep',
	components: { OnboardingDynamicField },
	messages: [OnboardingMessage, Profile],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		stepInfo: {
			type: Object,
			default: () => ({}),
		},
		flowName: {
			type: String,
			default: 'migrated_user_onboarding_distributors_affiliates',
		},
		importErrors: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			onboarding: new Onboarding(),
			stepCode: 'update_profile_information',
			form: {},
		};
	},
	computed: {
		fields() {
			try {
				const { fields } = this.stepInfo;
				const sortedFields = Object.fromEntries(
					FIELDS_SORT_ORDER
						.filter((key) => fields[key]) // Ensure only keys present in fields are used
						.map((key) => [key, fields[key]]), // Map to [key, value] pairs
				);
				return sortedFields;
			} catch (e) {
				return [];
			}
		},
	},
	methods: {
		updateValue(form) {
			Object.entries(form).forEach(([key, value]) => {
				if (value !== undefined) {
					this.$set(this.form, key, value);
				} else if (this.form[key] !== undefined) {
					this.$delete(this.form, key);
				}
			});

			this.$emit('update-form', this.form);
		},
	},
};
</script>
